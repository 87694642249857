import { ContactButton } from '../../components/contact-button';
import './style.css'
import { WhatsAppOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons';

export const Contact = ({title, sentence, phone, mail, adress}) => {
    return (
        <div className='background-image'>
            <div className='box'>
            <div>
                <div>
                    <h1 className="text-title montserrat navy-color">{title}</h1>
                    <div style={{fontSize: '1.1rem'}}>
                        <p>{sentence}</p>
                        <p>
                            <a style={{textDecoration: 'none', color: '#524C49'}} href="https://wa.me/5548996209579">
                                <WhatsAppOutlined style={{ fontSize: '20px', marginBottom: '-3px', paddingRight: '8px', color: '#0d7b00'}}/>
                                {phone}
                            </a>
                        </p>
                        <p><MailOutlined style={{ fontSize: '20px', marginBottom: '-3px', paddingRight: '8px'}}/>{mail}</p>
                        <p><EnvironmentOutlined style={{ fontSize: '20px', marginBottom: '-3px', paddingRight: '8px', color: '#FF0000'}}/>{adress}</p>
                    </div>
                    <br/>
                    <div style={{paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <ContactButton color={'navy'}/>
                    </div>
                </div>
            </div>
            </div>
        </div>
      );
  };
  