import './style.css'
import { StarFilled } from '@ant-design/icons';

const Stars = ({ count }) => {
    return Array.from({ length: count }, (_, index) => (
      <StarFilled key={index} style={{ color: '#fde910' }} />
    ));
};

export const ReviewCard = ({review, stars = 5}) => {
    return (
        <div className='review-card montserrat'>
            <div>
                <Stars count={stars} />
            </div>
            <p>{review}</p>
        </div>
      );
  };
  