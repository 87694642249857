import { ContactButton } from '../../components/contact-button';
import './style.css'

export const TextImage = ({subtitle, title, paragraphs, paragraphBold, image, altImage, imageRightSide, background, fontColorParagraphs, fontColorTitles, fontColorSubtitle, colorHr, buttonColor = 'rose', fontSize = '1.2rem'}) => {
    const styleMain = `border ${background != null ? background + "-background" : ""}`;
    const subtitleClassName = `montserrat-subtitle ${fontColorSubtitle}-color`;
    const titleClassName = `text-title ${fontColorTitles}-color`;
    const paragraphsClassName = `${fontColorParagraphs}-color ${imageRightSide}`;
    const hrClassName = `${colorHr}-background-color`
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };
    return (
        <div className={styleMain}>
            <div className="page-container">
            {!imageRightSide && 
                <div className="image-text-image">
                    <img src={image} alt={altImage} />
                </div>  
            }
            <div className="text-text-image">
                {subtitle != null && <h2 className={subtitleClassName}>{subtitle}<hr className={hrClassName}/></h2>}
                <h1 className={titleClassName} dangerouslySetInnerHTML={createMarkup(title)}></h1>
                {paragraphs?.map((element, index) => (
                    <p style={{fontSize: fontSize}} className={paragraphsClassName} key={index} dangerouslySetInnerHTML={createMarkup(element)}></p>
                    ))}
                <b>{paragraphBold}</b>
                <br/>
                <div className='button-section'>
                    <ContactButton color={buttonColor}/>
                </div>
            </div>
            {imageRightSide &&
                <div className="image-text-image">
                    <img src={image} alt={altImage} />
                </div>
            }
            </div>
        </div>
      );
  };
  