import { ContactButton } from '../../components/contact-button';
import './style.css'

export const Text = ({title, paragraphs, paragraphBold, background, subtitle, listItems, retreat = false, textAlign = 'left', buttonColor = 'rose', titleColor = 'rose', textColor = ''}) => {
    const className = `border ${background}-background color-white`
    const classNameParagraphs = `paragraph-section`
    const classNameParagraph = `paragraph-text-style ${retreat ? 'retreat' : ''} text-align-${textAlign}`
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };
    return (
        <div className={className}>
            <div className="page-container">
            <div className={classNameParagraphs}>
                <h1 style={{color: titleColor}} className="text-title montserrat">{title}</h1>
                {paragraphs?.map((element, index) => (
                    <p style={{color: textColor}} className={classNameParagraph} key={index} dangerouslySetInnerHTML={createMarkup(element)}></p>
                ))}
                <b>{paragraphBold}</b>
                <ul className="listItemsText">
                    {listItems?.map((element, index) => (
                        <li key={index}>{element}</li>
                    ))}
                </ul>
                <br/>
                <div style={{paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ContactButton color={buttonColor}/>
                </div>
            </div>
            </div>
        </div>
      );
  };
  