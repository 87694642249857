import './App.css';
import { TextImage } from './sections/TextImage';
import { Header } from './sections/Header';
import { Text } from './sections/Text';
import { Footer } from './sections/Footer';
import { Phrase } from './sections/Phrase';
import { Maps } from './sections/Maps';
import { Contact } from './sections/Contact';
import { WhatsAppIcon } from './components/WhatsappIcon';
import { Review } from './sections/Review';
import { YoutubeVideoV2 } from './sections/youtubeVideoV2';

function App() {
  return (
    <div className="App">
      <WhatsAppIcon/>
      <Header/>
      <TextImage 
        subtitle={'Psicóloga Leticia Kammer'} 
        title={'Em busca de uma vida mais <b>leve e feliz</b>?'}
        paragraphs={['Dê o primeiro passo rumo à transformação que você deseja. Entre em contato, e juntos(as) começaremos uma jornada de autodescoberta e renovação.']} 
        image={'/about.webp'}
        altImage={'Foto Principal'}
        imageRightSide={true}
        fontColorTitles={'navy'}
        fontColorSubtitle={'rose'}
        buttonColor={'navy'}
      />
      <Phrase titleFont={'montserrat'} title={'Serviços personalizados para jovens e adultos'} paragraphs={['Atendimento adaptado às suas necessidades: Online e Presencial.']} background={'light-blue'} buttonColor={'navy'}/>
      <Text titleFont={'montserrat'} subtitle={'Abordagem'} title={'Não Sabe se Terapia é pra Você?'} 
          paragraphs={
              [
                'A terapia é um espaço seguro e acolhedor, onde você será ouvido atentamente e compreendido em seus sentimentos, pensamentos e ações. Se você está lidando com ansiedade, enfrentando desafios em seus relacionamentos ou buscando maior autoconhecimento e crescimento pessoal, a terapia pode ser um recurso transformador.',
                'Com abordagens que respeitam a sua individualidade, juntas(os), vamos trabalhar para superar seus obstáculos e estimular mudanças positivas na sua vida. Se você procura mais equilíbrio emocional, fortalecimento da sua autoestima ou uma melhoria na qualidade de vida, convido você a explorar como a terapia pode ser um passo valioso em sua trajetória de bem-estar.',
                '<b>Vamos para dar o primeiro passo rumo a uma vida mais completa e satisfatória?</b> Entre em contato para iniciar essa caminhada.'
              ]
            } background={'white'}
          buttonColor={'rose'}
          retreat={true}
      />
      <Text titleFont={'montserrat'} 
            title={'Reflexão sobre nossas experiências'} 
            paragraphs={['Você já considerou como suas reações aos eventos podem ser respostas ao seu estilo de vida, em vez de transtornos médicos?',
                         'Frequentemente, sentimentos como cansaço ou estresse são interpretados como ansiedade ou depressão, sugerindo condições que precisam de tratamento.',
                         'Mas muitas vezes, essas sensações são apenas reflexos de como vivemos, como excesso de trabalho ou uso intensivo de redes sociais.',
                         'Reconhecer isso é crucial para buscar um equilíbrio saudável. <b>Entre em contato para iniciar essa jornada de autocompreensão.</b>']} 
            background={'light-blue'} buttonColor={'navy'} textColor = {'white'} titleColor = {'white'} retreat={true}
      />
      <TextImage  
        title={'Quem sou eu'}
        paragraphs={['Sou Letícia Kammer e utilizo a abordagem da Terapia de Aceitação e Compromisso (ACT) para oferecer um espaço seguro e acolhedor, onde você pode explorar as profundezas do seu ser, transformando adversidades em degraus para o seu desenvolvimento pessoal.',
        'Ajudo pessoas a compreender dos padrões que moldam suas experiências e percepções, para permitindo uma vida com mais autonomia e bem-estar.',
         'Através do processo terapêutico, busco identificar e compreender a raiz dos problemas que afetam a sua vida, sejam eles emocionais, psicológicos ou comportamentais. Esta abordagem permite não apenas um alívio dos sintomas, mas também uma mudança significativa na forma como você percebe a si mesmo e ao mundo ao seu redor. Com orientações personalizadas e adaptadas a cada caso, trabalhamos juntos para desbloquear o seu potencial pleno, promovendo uma jornada de autoconhecimento, cura e transformação.']} 
        image={'/about2.webp'}
        altImage={'Leticia'}
        imageRightSide={false}
        background={'white'}
        fontColorTitles={'navy'}
        fontColorSubtitle={'rose'}
        buttonColor={'navy'}
        colorHr={'white'}
        fontSize='1.1rem'
      />
      {/*<Slide/>*/}
      <Review reviews={["Estou amando os atendimentos, me sinto confortável e segura, e sempre saio com um entendimento melhor sobre o que conversamos, coisas que eu não consigo ver por conta própria, tudo fica mais claro, me sinto muito acolhida e compreendida!",
                        "Conheci Letícia em um programa de ajuda psicológica da universidade Unesc.Após as sessões do programa, tornei-me paciente dela. Sempre pontual, integra e direta, sinto-me confortável e seguro conversando com ela. Confesso que demorei para me abrir, mas agora espero ansiosamente pela próxima consulta.\nRecomendo muito!",
                        "Desde a primeira consulta com a Letícia, eu só tive experiências boas. Gosto do jeito que ela aborda os temas e faz entender o porque de sentir aquilo, ou de agir de tal maneira. Ela é uma ótima profissional!"]}/>
      {/*<YoutubeVideoV2
        src={"https://www.youtube.com/embed/lJIrF4YjHfQ?si=2zrK90nyAKDj9I5q"}
        title={"Conheça (adicionar título do vídeo)"}
          >
      </YoutubeVideoV2>*/}
      <Contact title={"Vamos conversar?"} sentence={"Sua jornada em busca de bem-estar e compreensão pode começar aqui. Estou pronta para te ouvir e caminhar ao seu lado. Entre em contato comigo, estou aqui para te ajudar."} phone={"(48) 99620-9579"} mail={"leticia_kammer@unesc.net"} adress={"Rua Getúlio Vargas, Edifício Milano, 7º andar - Sala 83."}/>
      <Maps></Maps>
      <Footer/>
    </div>
  );
}

export default App;


