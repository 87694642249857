import GoogleTagManager from '../Ads/GoogleTagManager';
import './style.css'
import {WhatsAppOutlined} from '@ant-design/icons'

export const Header = () => {
  const handleClick = () => {
    window.gtag('event', 'conversion', { 'send_to': 'AW-16517211920/53rzCID2yaIZEJDOgsQ9' });
    window.open('https://wa.me/5548996209579', 'noopener,noreferrer');
  };
  return (
    <div className="border">
    <header className="header">
      <div className="header-content"> 
        <div className="logo">
          <img src="/logo.webp" alt="Logo" />
          <div className='namePsi white-color alex-brush'>
            <h2 style={{fontWeight: '200'}}>Leticia</h2>
            <h2 style={{fontWeight: '200'}}>Kammer</h2>
          </div>
        </div>
        <button onClick={handleClick} className="contact-info">
          <WhatsAppOutlined style={{ fontSize: '15px', marginBottom: '-1px' }}/> (48) 99620-9579
        </button>
      </div>
      <GoogleTagManager/>
  </header>
    </div>
  );
};