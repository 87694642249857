import './style.css'
import { WhatsAppOutlined } from '@ant-design/icons';

export const WhatsAppIcon = () => {

    const handleClick = () => {
        window.gtag('event', 'conversion', { 'send_to': 'AW-16517211920/53rzCID2yaIZEJDOgsQ9' });
    };

    return (
        <>
          <a href="https://wa.me/5548996209579" target="_blank" rel="noopener noreferrer">
            <div id="whatasapp" onClick={handleClick}>
                <WhatsAppOutlined style={{color: '#fff', fontSize: '35px', marginBottom: '-3px' }}/>
            </div>
          </a>
       </>
      );
  };
  