import './style.css'

export const Maps = () => {
    return (
        <div className='container-maps'>
            <iframe 
                className='mapsFrame'
                title='local'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.492194664206!2d-49.37651321228071!3d-28.67491980630956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952182844127bc7d%3A0xf54422b772c0bff8!2sCondom%C3%ADnio%20Edif%C3%ADcio%20Milano%20-%20Av.%20Get%C3%BAlio%20Vargas%2C%20372%20-%20Centro%2C%20Crici%C3%BAma%20-%20SC%2C%2088801-500!5e0!3m2!1spt-BR!2sbr!4v1710718927882!5m2!1spt-BR!2sbr" 
                allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
      );
  };
  