import { WhatsAppOutlined } from '@ant-design/icons';
import './style.css'

export const ContactButton = ({color}) => {
    const className = `${color}-background info-button-${color} white-color`
    const handleClick = () => {
        window.gtag('event', 'conversion', { 'send_to': 'AW-16517211920/53rzCID2yaIZEJDOgsQ9' });
        window.open('https://wa.me/5548996209579', '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            <button onClick={handleClick} className={className}><WhatsAppOutlined style={{ fontSize: '20px', marginBottom: '-3px' }}/>ENTRE EM CONTATO</button>
        </>
      );
  };